import React, {useEffect, useState} from "react";
import Footer from "../../../component/footer/footer";
import "./opos.css";
import MenuAdministracion from "../../../component/menu/administrador/menu";
import {formatDate} from "../../../core/utiles";
import {
    callApiAdministrarEliminarOpo,
    callApiOposBuscarPorEtiqueta,
    callApiOposBuscarPorTexto,
    callApiOposConsultaPorDiasAtrasAdmin,
    callApiOposConsultaPorFecha,
    callApiOposConsultaPorFechaAdmin,
    callApiOposConsultaPorFechaYBopAdmin,
    callApiOposConsultaPorUuidOpoAdmin
} from "../../../core/restUtils";
import {useNavigate} from "react-router-dom";
import {URL_ADMIN_CREAR_PROCESO_OPO, URL_ADMIN_OPOS_MODIFICAR} from "../../../core/constantes";

export default function AdministrarOposAdmin() {

    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState();
    const [textoFiltrado, setTextoFiltrado] = useState();
    const [verSoloMostrables , setVerSoloMostrables] = useState();
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);
        buscarDatosOpos();
    }, []);

    const buscarDatosOpos = () => {

        // recuperamos el querystring
        const querystring = window.location.search
        console.log(querystring)

        // usando el querystring, creamos un objeto del tipo URLSearchParams
        const params = new URLSearchParams(querystring)
        console.log(params)

        if (params != null && params.get("etiquetaBusqueda") != null) {
            getDataOposPorEtiqueta(params.get("etiquetaBusqueda"));
        } if (params != null && params.get("fecha") != null && params.get("nomeBop") != null) {
            console.log("Buscado opos por fecha y nombre")
            getDataOposPorFechaParamAndBop(params.get("fecha"), params.get("nomeBop"));
        } else if (params != null && params.get("fecha") != null) {
            console.log("Buscado opos por fecha")
            getDataOposPorFechaParam(params.get("fecha"));
        } else if (params != null && params.get("opoUuid") != null) {
            console.log("Buscado ops por uuid")
            getDataOposPorUuid(params.get("opoUuid"));
        } else {
            console.log("Buscado opos por dias atras")
            // Buscar semana anterior
            getDataOposPorDiasAtras();
        }

        if (params != null && params.get("verSoloMostrables") != null) {
            setVerSoloMostrables(true);
        } else {
            setVerSoloMostrables(false);
        }

    }

    const eliminarOpo = async (uuidOpo) => {
        try {
            setLoading(true);
            await callApiAdministrarEliminarOpo(uuidOpo);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    const getDataOposPorEtiqueta = async (textoEtiqueta) => {
        try {
            setLoading(true);
            const result = await callApiOposBuscarPorEtiqueta(textoEtiqueta)
            setPosts(result);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    const getDataOposPorDiasAtras = async () => {
        try {
            setLoading(true);
            const result = await callApiOposConsultaPorDiasAtrasAdmin(7)
            setPosts(result);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    const getDataOposPorUuid = async (uuid) => {
        try {
            setLoading(true);
            const result = await callApiOposConsultaPorUuidOpoAdmin(uuid)
            setPosts(result);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    const getDataOposPorFechaParam = async (fecha) => {
        try {
            setLoading(true);
            const result = await callApiOposConsultaPorFechaAdmin(fecha)
            setPosts(result);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    const getDataOposPorFechaParamAndBop = async (fecha, bop) => {
        try {
            setLoading(true);
            const result = await callApiOposConsultaPorFechaYBopAdmin(fecha, bop)
            setPosts(result);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    const getDataOposPorFecha = async () => {
        try {
            setLoading(true);
            const result = await callApiOposConsultaPorFecha(data)
            setPosts(result);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    const getDataOposPorTexto = async () => {
        try {
            setLoading(true);
            const result = await callApiOposBuscarPorTexto(textoFiltrado)
            setPosts(result);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    const filterByDate = (event) => {
        event.preventDefault();

        getDataOposPorFecha();
    };

    const filterByText = (event) => {
        event.preventDefault();

        getDataOposPorTexto();
    };

    function handleEliminarSubmit(evt) {
        /*
          Previene el comportamiento default de los
          formularios el cual recarga el sitio
        */
        evt.preventDefault();

        // Consultar uuid
        const buttonValue = evt.target.querySelector('button[type="submit"]').value;

        eliminarOpo(buttonValue);

        // Recargamos bops
        buscarDatosOpos();

    }

    function handleModificarSubmit(evt) {
        /*
          Previene el comportamiento default de los
          formularios el cual recarga el sitio
        */
        evt.preventDefault();

        // Consultar uuid
        const buttonValue = evt.target.querySelector('button[type="submit"]').value;

        //navigate(URL_ADMIN_OPOS_MODIFICAR+buttonValue);
        window.open(URL_ADMIN_OPOS_MODIFICAR+buttonValue, '_blank');
    }

    function handleCrearProcesoSubmit(evt) {
        /*
          Previene el comportamiento default de los
          formularios el cual recarga el sitio
        */
        evt.preventDefault();

        // Consultar uuid
        const buttonValue = evt.target.querySelector('button[type="submit"]').value;

        navigate(URL_ADMIN_CREAR_PROCESO_OPO+buttonValue);

    }

    const handleInputDateChange = (event) => {
        setData(event.target.value);
    };

    const handleInputTextChange = (event) => {
        setTextoFiltrado(event.target.value);
    };

    const handleInputFiltradoChange = (event) => {
        setVerSoloMostrables(event.target.value);
    };

    const renderOpo = (item, index) => {

        // Formatear a data
        const fechaFormateada = formatDate(item.fechaBop[2], item.fechaBop[1], item.fechaBop[0])

        return (
            <>
                <ul>
                    <div className="oposicion" key={index}>
                        <h2>Oposición: {item.organizacion}</h2>
                        <h3>Fecha BOP: {fechaFormateada}</h3>
                        <h3>BOP: {item.bop}</h3>
                        <h3>UUID: {item.uuid}</h3>
                        <h3>Mostrable: {item.verificado ? 'SI' : 'NO'}</h3>
                        <p><strong>Descripción:</strong> {item.descripcion}</p>
                        <p><a href={item.urlDetalle} rel="noopener" target="_blank">Más información</a></p>
                        <div className="oposForms">
                            <form onSubmit={handleModificarSubmit}>
                                <button type="submit" value={item.uuid}>Modificar</button>
                            </form>
                            <form onSubmit={handleCrearProcesoSubmit}>
                                <button type="submit" value={item.uuid}>Crear proceso Opo</button>
                            </form>
                            <form onSubmit={handleEliminarSubmit}>
                                <button type="submit" value={item.uuid}>Eliminar</button>
                            </form>
                        </div>
                    </div>
                </ul>
            </>
        )

    }

    const renderOpos = () => {

        if (loading) return <div className="carga"><p><img key="carga" alt="carga" src="/public/loading.gif" height="100" width="100"/></p></div>;
        if (error) return <div className="carga"><p>Error: {error.message}</p></div>;

        return posts.map((item, index) => {

            if (verSoloMostrables && item.verificado) {
                return renderOpo(item, index)
            } else if (!verSoloMostrables) {
                return renderOpo(item, index)
            }

        });
    };

    return (
        <>
            {<MenuAdministracion/>}
            <div className="search-container">
                <div className="buscador" key="containerBuscadorPorFecha">
                    <label htmlFor="search-date">Buscar por fecha:</label>
                    <input type="date" key="search-date" name="search-date" value={data}
                           onChange={handleInputDateChange}/>
                    <button onClick={filterByDate}>Buscar</button>
                </div>
                <div className="buscador" key="containerBuscadorPorCadena">
                    <label htmlFor="search-text">Buscar por texto:</label>
                    <input type="text" key="search-text" name="search-text" value={textoFiltrado}
                           onChange={handleInputTextChange}/>
                    <button onClick={filterByText}>Buscar</button>
                </div>
            </div>
            <div className="content">
                <h1>Listado de Opos</h1>
                {renderOpos()}
            </div>
            {<Footer/>}
        </>
    );

}