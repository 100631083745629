import axios from 'axios';
import {
    OAUTH_CLIENT_ID,
    OAUTH_CLIENT_SECRET,
    OAUTH_GRANT_TYPE,
    OAUTH_GRANT_TYPE_REFRESH,
    OAUTH_REDIRECT_URL, SESION_TOKEN_JWT,
    SESION_TOKEN_REFRESCO_JWT,
    URL_API
} from "./constantes";

export const callApiOposConsultaPorFecha = async (date) => {
    try {
        const response = await axios.get(`${URL_API}/api/v1/opos/consulta?date=${date}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};

export const callApiOposConsultaPorFechaAndBop = async (date, bop) => {
    try {
        const response = await axios.get(`${URL_API}/api/v1/opos/consulta?date=${date}&bop=${bop}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};

export const callApiOposConsultaPorFechaYBop = async (date, bop) => {
    try {
        const response = await axios.get(`${URL_API}/api/v1/opos/consulta?date=${date}&bop=${bop}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};

export const callApiOposConsultaPorDiasAtras = async (dias) => {
    try {
        const response = await axios.get(`${URL_API}/api/v1/opos/consultaDias?dias=${dias}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};

export const callApiOposBuscarPorTexto = async (textoFiltrado) => {
    try {
        const response = await axios.get(`${URL_API}/api/v1/opos/consultaTexto?textoBusqueda=${textoFiltrado}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
}

export const callApiOposBuscarPorEtiqueta = async (textoEtiquetas) => {
    try {
        const response = await axios.get(`${URL_API}/api/v1/opos/consulta/etiqueta?etiquetaBusqueda=${textoEtiquetas}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
}

export const callApiOposBuscarOposicionDetalleUrlAmigable = async (urlAmigable) => {
    try {
        const response = await axios.get(`${URL_API}/api/v1/opos/ver/procesoOposicion?urlAmigable=${urlAmigable}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
}

export const callApiOposConsultarEtiquetas = async () => {
    try {
        const response = await axios.get(`${URL_API}/api/v1/opos/etiquetas`);
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
}

export const callApiOposConsultarBops = async () => {
    try {
        const response = await axios.get(`${URL_API}/api/v1/opos/bop/consulta`);
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
}

export const callApiOposConsultarWebsBopsFechaActual = async (date) => {
    try {
        const response = await axios.get(`${URL_API}/api/v1/opos/bop/listadoBopsWeb?date=${date}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
}

// Chamdas administracion

export const callApiOposConsultaPorUuidOpoAdmin = async (uuid) => {
    try {
        const response = await axios.get(`${URL_API}/api/v1/config/opos/consultaUuid/?uuid=${uuid}`,
            {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem(SESION_TOKEN_JWT)}`,
                    'Content-Type': 'application/json'
                }
            });
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};

export const callApiProcesoOposicionConsultaPorUuidOpoAdmin = async (uuid) => {
    try {
        const response = await axios.get(`${URL_API}/api/v1/config/ver/procesoOposicionPorUuid?uuid=${uuid}`,
            {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem(SESION_TOKEN_JWT)}`,
                    'Content-Type': 'application/json'
                }
            });
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};

export const callApiOposConsultaProcesosOposAdmin = async (numInicio, numFin) => {
    try {
        const response = await axios.get(`${URL_API}/api/v1/config/ver/procesoOposicion?numInicio=${numInicio}&numFin=${numFin}`,
            {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem(SESION_TOKEN_JWT)}`,
                    'Content-Type': 'application/json'
                }
            });
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};

export const callApiOposConsultaPorFechaAdmin = async (date) => {
    try {
        const response = await axios.get(`${URL_API}/api/v1/config/opos/consulta?date=${date}`,
            {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem(SESION_TOKEN_JWT)}`,
                    'Content-Type': 'application/json'
                }
            });
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};

export const callApiOposConsultaPorFechaYBopAdmin = async (date, bop) => {
    try {
        const response = await axios.get(`${URL_API}/api/v1/config/opos/consulta?date=${date}&bop=${bop}`,
            {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem(SESION_TOKEN_JWT)}`,
                    'Content-Type': 'application/json'
                }
            });
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};

export const callApiOposConsultaPorDiasAtrasAdmin = async (dias) => {
    try {
        const response = await axios.get(`${URL_API}/api/v1/config/opos/consultaDias?dias=${dias}`,
            {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem(SESION_TOKEN_JWT)}`,
                    'Content-Type': 'application/json'
                }
            });
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};

export const callApiMaestrosVerEtiquetas = async () => {
    try {
        const response = await axios.get(`${URL_API}/api/v1/config/configurar/etiquetas/ver`,
            {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem(SESION_TOKEN_JWT)}`,
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
}

export const callApiMaestrosVerBusqueda = async () => {
    try {
        const response = await axios.get(`${URL_API}/api/v1/config/configurar/busqueda/ver`,
            {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem(SESION_TOKEN_JWT)}`,
                    'Content-Type': 'application/json'
                }
            });
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
}

export const callApiMaestrosAddBusqueda = async (bop, texto, prioridad, indexable) => {
    try {
        const response = await axios.post(`${URL_API}/api/v1/config/configurar/busqueda`,
            [
                    {
                        textoBusqueda: texto,
                        bop: bop,
                        indexable: indexable,
                        prioridad: prioridad
                    }
                ],{
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem(SESION_TOKEN_JWT)}`,
                    'Content-Type': 'application/json'
                }
            });
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
}

export const callApiAdministrarRelanzarConfig = async (fecha, envioNotificacion) => {
    try {
        const response = await axios.post(`${URL_API}/api/v1/config/relazar/lecturaBop`,
            {
                fecha: fecha,
                envioNotificacion: envioNotificacion,

            },{
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem(SESION_TOKEN_JWT)}`,
                    'Content-Type': 'application/json'
                }
            });
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
}

export const callApiAdministrarRelanzarAnalisisConfig = async (fecha) => {
    try {
        const response = await axios.post(`${URL_API}/api/v1/config/relazar/analisis`,
            {
                fecha: fecha

            },{
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem(SESION_TOKEN_JWT)}`,
                    'Content-Type': 'application/json'
                }
            });
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
}

export const callApiAdministrarEliminarBop = async (uuidBop) => {
    try {
        const response = await axios.delete(`${URL_API}/api/v1/config/bop/eliminar`,
            {
                data: {
                    uuidBop: uuidBop
                },
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem(SESION_TOKEN_JWT)}`,
                    'Content-Type': 'application/json'
                }
            });
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
}

export const callApiAdministrarModificarOpo = async (uuidOpo, organizacion, descripcion, urlDetalle, uuidProcesoSelectivo, verificado) => {
    try {
        const response = await axios.post(`${URL_API}/api/v1/config/opo/modificar`,
            {
                uuidOpo: uuidOpo,
                organizacion: organizacion,
                descripcion: descripcion,
                urlDetalle: urlDetalle,
                uuidProcesoSelectivo: uuidProcesoSelectivo,
                verificado: verificado
            },
            {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem(SESION_TOKEN_JWT)}`,
                    'Content-Type': 'application/json'
                }
            });
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
}

export const callApiAdministrarCrearProcesoOpo = async (urlAmigable, organizacion, urlImagen, titulo, descripcion, sistemaSeleccion, tipoContrato, uuidOpoInicio, procesoFinalizado, procesoMostrable) => {
    try {
        const response = await axios.post(`${URL_API}/api/v1/config/crear/procesoOposicion`,
            {
                urlAmigable: urlAmigable,
                organizacion: organizacion,
                urlImagen: urlImagen,
                titulo: titulo,
                sistemaSeleccion: sistemaSeleccion,
                tipoContrato: tipoContrato,
                descripcion: descripcion,
                uuidOpoInicio: uuidOpoInicio,
                procesoFinalizado: procesoFinalizado,
                procesoMostrable: procesoMostrable
            },
            {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem(SESION_TOKEN_JWT)}`,
                    'Content-Type': 'application/json'
                }
            });
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
}

export const callApiAdministrarModificarProcesoOpo = async (urlAmigable, organizacion, urlImagen, titulo, descripcion, sistemaSeleccion, tipoContrato, uuidOpoInicio, procesoFinalizado, procesoMostrable) => {
    try {
        const response = await axios.post(`${URL_API}/api/v1/config/modificar/procesoOposicion`,
            {
                urlAmigable: urlAmigable,
                organizacion: organizacion,
                urlImagen: urlImagen,
                titulo: titulo,
                sistemaSeleccion: sistemaSeleccion,
                tipoContrato: tipoContrato,
                descripcion: descripcion,
                uuidOpoInicio: uuidOpoInicio,
                procesoFinalizado: JSON.parse(procesoFinalizado),
                procesoMostrable: JSON.parse(procesoMostrable)
            },
            {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem(SESION_TOKEN_JWT)}`,
                    'Content-Type': 'application/json'
                }
            });
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
}

export const callApiAdministrarAddFaseProcesoOpo = async (uuidOposicionDetalle, uuidOposicion, titulo, url, fechaBop) => {
    try {
        const response = await axios.post(`${URL_API}/api/v1/config/asignar/opoProcesoOposicion`,
            {
                uuidOposicion: uuidOposicion,
                uuidOposicionDetalle: uuidOposicionDetalle,
                titulo: titulo,
                url: url,
                fechaBop: fechaBop
            },
            {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem(SESION_TOKEN_JWT)}`,
                    'Content-Type': 'application/json'
                }
            });
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
}

export const callApiAdministrarEliminarOpo = async (uuidOpo) => {
    try {
        const response = await axios.delete(`${URL_API}/api/v1/config/opo/eliminar`,
            {
                data: {
                    uuidOpo: uuidOpo
                },
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem(SESION_TOKEN_JWT)}`,
                    'Content-Type': 'application/json'
                }
            });
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
}

export const callApiImpectionOpos = async () => {
    try {
        const response = await axios.get(`${URL_API}/api/v1/oauth/authorizationAdmin`,
            {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem(SESION_TOKEN_JWT)}`,
                    'Content-Type': 'application/json'
                }
            });
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
}

export const callApiAdministrarVerNotificaciones = async () => {
    try {
        const response = await axios.get(`${URL_API}/api/v1/estadisticas/envioNotificacion/`,
            {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem(SESION_TOKEN_JWT)}`,
                    'Content-Type': 'application/json'
                }
            });
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
}

export const callApiAdministrarVerNotificacionFecha = async (fecha) => {
    try {
        const response = await axios.get(`${URL_API}/api/v1/estadisticas/envioNotificacion/?fecha=${fecha}`,
            {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem(SESION_TOKEN_JWT)}`,
                    'Content-Type': 'application/json'
                }
            });
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
}

export const callApiAdministrarVerEstadisticas = async (numeroInicio, numeroFin) => {
    try {
        const response = await axios.get(`${URL_API}/api/v1/estadisticas/verEstadisticas?numeroInicio=${numeroInicio}&numeroFin=${numeroFin}`,
            {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem(SESION_TOKEN_JWT)}`,
                    'Content-Type': 'application/json'
                }
            });
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
}

export const callApiAdministrarEliminarFaseProcesoOpo = async (uuidFaseProcesoOpo) => {
    try {
        const response = await axios.delete(`${URL_API}/api/v1/config/eliminar/opoProcesoOposicion`,
            {
                data: {
                    uuidOpo: uuidFaseProcesoOpo
                },
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem(SESION_TOKEN_JWT)}`,
                    'Content-Type': 'application/json'
                }
            });
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
}

// Chamadas Oauth
export const callApiToken = async (code) => {

    try {
        const response = await axios.post(
            'https://oauth.reyesi.com.es/realms/ReyesI/protocol/openid-connect/token',
            {
                code: code,
                client_id: OAUTH_CLIENT_ID, // Reemplaza con tu client_id
                client_secret: OAUTH_CLIENT_SECRET, // Reemplaza con tu client_secret
                redirect_uri: OAUTH_REDIRECT_URL, // Asegúrate de que coincida con tu URI de redireccionamiento
                grant_type: OAUTH_GRANT_TYPE
            },
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            });
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
}

export const callApiRefreshToken = async () => {

    try {
        const response = await axios.post(
            'https://oauth.reyesi.com.es/realms/ReyesI/protocol/openid-connect/token',
            {
                client_id: OAUTH_CLIENT_ID, // Reemplaza con tu client_id
                client_secret: OAUTH_CLIENT_SECRET, // Reemplaza con tu client_secret
                refresh_token: localStorage.getItem(SESION_TOKEN_REFRESCO_JWT),
                grant_type: OAUTH_GRANT_TYPE_REFRESH
            },
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            });
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
}

export const callApiInstrospeccion = async (token) => {

    try {
        const response = await axios.post(
            'https://oauth.reyesi.com.es/realms/ReyesI/protocol/openid-connect/token/introspect',
            {
                token: token,
                client_id: OAUTH_CLIENT_ID, // Reemplaza con tu client_id
                client_secret: OAUTH_CLIENT_SECRET // Reemplaza con tu client_secret
            },
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            });
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
}